/* Base styles */
.dream-feed-placeholder-column {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  min-width: 165px;
  max-width: 165px;
}

.dream-placeholder-card {
  background-color: #3b3282;
  color: white;
  border-radius: 30px;
  margin-bottom: 1rem;
}

.dream-placeholder-card-button-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: 10px;
}

.dream-placeholder-card-button {
  color: white !important;
  border: solid 2px #4d2285;
  border-radius: 30px;
  padding: 35px 25px 18px 25px;
}

/* Height variations */
.dream-placeholder-card.height-1 {
  height: 175px;
}

.dream-placeholder-card.height-2 {
  height: 230px;
}

.dream-placeholder-card.height-3 {
  height: 275px;
}

.dream-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

/* Responsive styles */
@media (max-width: 767px) {
  .dream-feed-placeholder-column {
    margin: 0 5px;
    min-width: 125px;
    max-width: 125px;
  }

  .dream-placeholder-card.height-1 {
    height: 155px;
  }

  .dream-placeholder-card.height-2 {
    height: 200px;
  }

  .dream-placeholder-card.height-3 {
    height: 220px;
  }

  .dream-placeholder-card-button-container {
    padding: 0;
  }
}
