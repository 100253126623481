@font-face {
  font-family: "Poppins";
  src: url("./assets//fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

:root {
  --dark: #0f143c !important;
  --primary: #638ffd !important;
  --color-primary-dark: #1f2638;
  --color-primary: #638ffd;
  --color-secondary: #5f65be;
  --color-tertiary: #a178da;
  --color-accent: #4e4282;
  --color-accent-dark: #383282;
  --color-accent-darker: #2b3373;
  --color-gray: #c4c4c4;
  --color-background: #f5f6f7;
  --color-white: #ffffff;
}

body {
  height: 100vh;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  background: linear-gradient(#0f143c, #4d2285) !important;
}

.logo-navbar {
  max-width: 75px;
  max-height: 60px;
}

.bg-primary {
  background-color: #638ffd;
}

.bg-dark-select * {
  background-color: #212529 !important;
  color: white !important;
}

.bg-particles {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./assets/img/evolbs_icon15.png");
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:focus-within,
.btn-outline-primary:active,
.btn-outline-primary:hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  background-color: transparent !important;
}

.btn-circle {
  margin: 0;
  padding: 8px 13px;
  border-radius: 50% !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-red {
  color: rgb(230, 95, 95);
}

.hidden {
  display: none !important;
}

.container {
  max-width: 1000px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus-within,
.btn-primary:focus-visible,
.btn-primary:active,
.btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 15px;
}

#modal > .modal-dialog {
  width: fit-content;
  min-width: 500px;
  max-width: 1400px;
}

@media (max-width: 767px) {
  #modal > .modal-dialog {
    min-width: 95%;
  }
}

.card,
.modal-content,
.modal-dialog,
.modal-body {
  color: var(--light);
  background-color: var(--dark) !important;
}

.form-control,
.form-control:focus {
  border-radius: 15px;
  border: none;
  padding: 15px;
  background-color: var(--color-accent-dark);
  color: white;
}

.form-control::placeholder {
  color: white;
}

.modal-body {
  padding-top: 0px;
  padding-bottom: 1.5rem;
  border-radius: 0px 0px 15px 15px;
}

.modal-content,
.modal-dialog {
  border-radius: 15px;
}

label {
  margin-bottom: 0.5rem;
}

.card {
  border-radius: 15px;
}

.card-body {
  padding: 1.5rem;
}

.stats-card {
  border-radius: 25px;
  background-color: var(--color-accent-dark);
}

.purple-text {
  color: #4e4282 !important;
}

.show-mobile {
  display: none !important;
}

.freud {
  max-width: 100px;
}

.dream-form {
  max-width: 500px;
  margin: auto;
}

.loading-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 30, 68, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

#root {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.stats-card.number {
  display: flex;
  align-items: center;
  text-align: center;
  width: 185px;
  height: 230px;
}

.number-card-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 1000px) {
  .number-card-container {
    width: 33%;
  }
}

@media (max-width: 1000px) {
  .hide-tablet {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block !important;
  }

  .stats-card.number {
    width: 170px !important;
    height: 200px !important;
  }

  .stats-dates-filters {
    margin-bottom: 1rem !important;
  }

  .stats-graph-container {
    width: 100%;
  }

  .stats-global-stats-container {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

.dream-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 50px;
  min-height: 300px;
  min-width: 220px;
  max-width: 220px;
  padding: 18px;
  background: no-repeat;
  background-size: cover;
  background-color: gray;
}

.dream-interpretation-image-container {
  border-radius: 40px;
  background: no-repeat;
  background-size: cover;
  background-position: center center;
  min-width: 300px;
  max-height: 450px;
  overflow: hidden;
  width: 300px;
  background-image: url("https://media.giphy.com/media/ycfHiJV6WZnQDFjSWH/giphy.gif");
}

.dream-interpretation-image {
  max-width: 350px;
  min-height: 450px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  border-radius: 40px;
  background: no-repeat;
  background-size: cover;
  background-position: center center;
}

.dream-interpretation-image > .centered-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
